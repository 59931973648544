import React, { useEffect, useState } from 'react';
import { objectOf, arrayOf, any } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../components/customer/header';
import Footer from '../components/footer';
import SearchBarResults from '../components/customer/searchResults';
import { searchBarProps } from '../components/customer/props';
import Loading from '../components/loading';
import DesktopSearch from '../components/customer/desktopSearch';
import MobileSearch from '../components/customer/mobileSearch';
import useConfigs from '../hooks/useConfigs';
import Layout from '../components/layout';
import LAYOUT from '../constants/layout';
import COLORS from '../constants/colors';
import HomeGrid from '../components/HomeGrid';

const useStyles = makeStyles(({ spacing, zIndex, breakpoints }) => ({
  root: {
    margin: spacing(0),
  },
  header: {
    position: 'fixed',
    boxShadow: COLORS.SHADOW_STANDARD,
    zIndex: zIndex.appBar,
  },
  searchDesktop: {
    display: 'none',
  },
  searchMobile: {
    display: 'block',
    width: '100%',
  },
  [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
    searchDesktop: {
      display: 'block',
      width: '100%',
    },
    searchMobile: {
      display: 'none',
    },
  },
}));

const SearchResult = ({ submittedSearchBarDeals, searchText }) => {
  const s = useStyles();
  const [textSearch, setTextSearch] = useState('');

  useConfigs();

  useEffect(() => {
    setTextSearch(get(searchText, 'searchText', ''));
  }, [searchText]);

  return (
    <>
      <Layout>
        <HomeGrid type="section" fluid direction="column" className={s.root}>
          <HomeGrid fluid container className={s.header}>
            <Header />
            <HomeGrid className={s.searchDesktop}>
              <DesktopSearch />
            </HomeGrid>
            <HomeGrid className={s.searchMobile}>
              <MobileSearch flying={false} />
            </HomeGrid>
          </HomeGrid>
          <SearchBarResults
            searchBarDeals={submittedSearchBarDeals}
            searchText={textSearch}
          />
          <Footer cta />
        </HomeGrid>
        <Loading />
      </Layout>
    </>
  );
};

SearchResult.propTypes = {
  submittedSearchBarDeals: arrayOf(any),
  searchText: objectOf(any),
};

SearchResult.defaultProps = {
  submittedSearchBarDeals: [],
  searchText: {},
};

export default connect(
  searchBarProps.mapStateToProps,
  searchBarProps.mapDispatchToProps,
)(SearchResult);
