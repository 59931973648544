import React, { useState, useEffect } from 'react';
import { arrayOf, any, string } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, navigate } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { trim } from '../../utils/string';
import ROUTER from '../../constants/router';
import LAYOUT from '../../constants/layout';
import DealPanel from '../deal/dealpanel';
import HomeGrid from '../HomeGrid';

const useStyles = makeStyles(({ spacing, palette, typography, breakpoints }) => ({
  root: {
    margin: `${spacing(LAYOUT.HEADER_SCROLL_TOP)}px auto ${spacing(3)}px`,
  },
  breadcrumb: {
    marginTop: spacing(7),
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbText: {
    fontSize: spacing(2),
    color: palette.text.primary,
    textDecoration: 'none',
  },
  textBold: {
    fontWeight: typography.fontWeightBold,
  },
  title: {
    fontSize: spacing(3),
    textAlign: 'center',
    margin: spacing(2, 0),
  },
  [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
    root: {
      margin: `${spacing(LAYOUT.HEADER_SCROLL_TOP * 1.5)} auto ${spacing(6)}px`,
      maxWidth: spacing(LAYOUT.MAX_WIDTH),
    },
    title: {
      fontSize: spacing(5),
      margin: spacing(4),
    },
  },
}));

const SearchResults = ({ searchBarDeals, searchText }) => {
  const s = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));
  const [dealCount, setDealCount] = useState(0);
  const [searchTitle, setSearchTitle] = useState('');

  useEffect(() => {
    setDealCount(searchBarDeals.length);
  }, [searchBarDeals, desktop]);

  useEffect(() => {
    setSearchTitle(searchText);
  }, [searchText]);

  const handleViewDetail = (title, id) => {
    navigate(`${ROUTER.CUSTOMER.DEAL_DETAIL}/?${title}?${id}`);
  };

  return (
    <HomeGrid type="section" fluid direction="column" className={s.root}>
      {desktop && (
        <HomeGrid fluid alignItems="center" className={s.breadcrumb}>
          <Link to={ROUTER.CUSTOMER.ROOT} replace className={s.breadcrumbText}>
            {LAYOUT.HOME_PAGE}
          </Link>
          <ChevronRight className={s.icon} />
          <Typography className={`${s.breadcrumbText} ${s.textBold}`}>
            {LAYOUT.RESULT_PAGE}
          </Typography>
        </HomeGrid>
      )}
      <HomeGrid fluid justify="center" alignItems="center">
        <Typography className={s.title}>
          {dealCount} {LAYOUT.DEAL_TEXT} <strong>{trim(searchTitle, 20)}</strong>
        </Typography>
      </HomeGrid>
      {dealCount > 0 && (
        <DealPanel onDetail={handleViewDetail} deals={searchBarDeals} />
      )}
    </HomeGrid>
  );
};

SearchResults.propTypes = {
  searchBarDeals: arrayOf(any).isRequired,
  searchText: string.isRequired,
};

export default SearchResults;
